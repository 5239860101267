
import { IonPage, IonContent, IonIcon, IonImg } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import { defineComponent, ref, reactive, watch } from "vue";
//import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
//import { useGlobalFunctions } from "@/libs/globalFunctions";
import compareApp from "@/libs/comparelist";
import { useLocalStorage } from "@/libs/localStorage";
import { useStore } from "@/store";
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
export default defineComponent({
  name: "Compare",
  setup() {
    const { t, tm } = useI18n();
    const { getLocalStorageData } = useLocalStorage();
    //const { createLoading } = useGlobalFunctions();
    const storeX = useStore();
    const icons = reactive({
      trashOutline,
    });

    const compareItems = ref([]);
    const compareAttrs = ref({});

    const getProductToCompare = () => {
      compareItems.value = [];
      compareApp
        .getProductsToCompare()
        .then((resp: any) => {
          if (resp.success) {
            compareItems.value = resp.items;
            compareAttrs.value = resp.attrs;
          } else {
            compareItems.value = [];
            compareAttrs.value = {};
          }
        })
        .catch(() => {
          compareItems.value = [];
          compareAttrs.value = {};
        });
    };

    const removeProductToCompare = (product) => {
      compareApp
        .removeFromCompare(product)
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            getProductToCompare();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getImageUrl = (product) => {
      if (product.image == "no_selection") {
        return "assets/images/placeholder.png";
      } else if (product.image) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.image
        );
      } else if (product.thumbnail) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.thumbnail
        );
      } else if (product.small_image) {
        return (
          getLocalStorageData("websiteUrl") +
          "media/catalog/product" +
          product.small_image
        );
      } else {
        return "assets/images/placeholder.png";
      }
    };

    const getAttrValue = (compareAttr, compareItem) => {
      const attributeCode = compareAttr.attribute_code;
      let value = compareItem[attributeCode];

      if (attributeCode == "price") {
        value = value * 1 + " " + storeX.state.currency;
      }

      if (
        compareAttr.frontend_input == "select" ||
        compareAttr.frontend_input == "multiselect"
      ) {
        if (value) {
          if (!Array.isArray(value)) {
            value = value.split(",");
          }
          const finalValue = [];
          value.forEach((element) => {
            finalValue.push(compareAttr.options_values[element]);
          });
          value = finalValue.join(", ");
        }
      }
      return value;
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        getProductToCompare();
      }
    );
    function ionViewWillEnter() {
      getProductToCompare();
    }

    return {
      t,
      tm,
      icons,
      storeX,
      compareItems,
      compareAttrs,
      removeProductToCompare,
      getImageUrl,
      getAttrValue,
      ionViewWillEnter,
    };
  },
  components: {
    IonPage,
    IonContent,
    MainHeader,
    MainFooter,
    IonIcon,
    IonImg,
  },
});
